/**
 * @generated SignedSource<<694547e2880b30895706decca7e8a4ee>>
 * @relayHash b0dcda8e58c89d979fbd82744fd3556f
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-finding/504.0.0-2025-04-03T17:05:40.233Z/usePhotosLikeDataRefetchQuery

import { ConcreteRequest } from 'relay-runtime';
export type usePhotosLikeDataRefetchQuery$variables = {
  hasUserId?: boolean | null;
  photoIds?: ReadonlyArray<string> | null;
  userId?: string | null;
};
export type usePhotosLikeDataRefetchQuery$data = {
  readonly viewer: {
    readonly photosSearchBrowseQuery?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly photoLike: {
            readonly photoLikeId: string | null;
          } | null;
          readonly serviceId: string | null;
        } | null;
      } | null> | null;
    } | null;
  };
};
export type usePhotosLikeDataRefetchQuery = {
  response: usePhotosLikeDataRefetchQuery$data;
  variables: usePhotosLikeDataRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "hasUserId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "photoIds"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "photoIds",
    "variableName": "photoIds"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "PhotoLike",
  "kind": "LinkedField",
  "name": "photoLike",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "userId",
          "variableName": "userId"
        }
      ],
      "kind": "ScalarField",
      "name": "photoLikeId",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "usePhotosLikeDataRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "condition": "hasUserId",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "PhotosSearchBrowseQueryConnection",
                "kind": "LinkedField",
                "name": "photosSearchBrowseQuery",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PhotosSearchBrowseQueryEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PhotosSearchBrowsePhoto",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "usePhotosLikeDataRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "condition": "hasUserId",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "PhotosSearchBrowseQueryConnection",
                "kind": "LinkedField",
                "name": "photosSearchBrowseQuery",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PhotosSearchBrowseQueryEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PhotosSearchBrowsePhoto",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ]
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-finding/504.0.0-2025-04-03T17:05:40.233Z/usePhotosLikeDataRefetchQuery",
    "metadata": {},
    "name": "usePhotosLikeDataRefetchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "0ff42bc496b7120fd2756b98ff33079e";

export default node;
